@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-Black.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-Bold.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-Light.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-Medium.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-Regular.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('Fonts/Trap-SemiBold.otf') format('opentype');
}

html, body {
    font-family: 'Trap', sans-serif;
}
